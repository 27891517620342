<template>
  <div class="wedding-photography">
    <BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
    <div class="col-main-box">
      <Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
      <div class="col-r-box">
        <!-- 导航栏 -->
        <div class="crumbs">
          <div class="cru-logo">
            <img src="../../assets/images/home/daohang_logo.png" alt="">
          </div>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>婚礼摄影</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="head-search" :class="hide? 'hidediv':''">
          <div class="head-title">
            <img src="../../assets/images/sidder/hlsx_dangqi.png" alt="">
            <span>档期查询</span>
          </div>
          <!-- <div class="year-box">
            <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy" popper-class='yearStyle'
              placeholder="选择年" @change="yearchange" :clearable='false' :picker-options="yearoptions">
            </el-date-picker>
          </div>
          <div class="month-box">
            <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
              format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions">
            </el-date-picker>
          </div>
          <div class="day-box" style="border-right: none;">
            <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
              format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions">
            </el-date-picker>
          </div> -->

          <div class="all-time">
            <div class="re-data">
              <div class="day-box" style="border-right: none;">
                <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                  format="" placeholder="" @change="datechange" :clearable='false' :picker-options="dayoptions">
                </el-date-picker>
              </div>
            </div>
            <div class="year-box">
              <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy" popper-class='yearStyle'
                placeholder="选择年" @change="yearchange" :clearable='false' :picker-options="yearoptions" disabled>
              </el-date-picker>
            </div>
            <div class="month-box">
              <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
                format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions" disabled>
              </el-date-picker>
            </div>
            <div class="day-box" style="border-right: none;">
              <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions" disabled>
              </el-date-picker>
            </div>
          </div>
          <!-- <div class="select-box">
            <el-select v-model="search_time" placeholder="请选择" @change="dangqichange">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div> -->
          <div class="search-btn" @click="inquiry">查询</div>
        </div>
        <!-- 屏幕过小时 -->
        <div class="head-search" :class="hide? 'hidediv':'head-search1'">
          <div class="head-title">
            <img src="../../assets/images/sidder/hlsx_dangqi.png" alt="">
            <span>档期查询</span>
          </div>
          <div class="second-box">
           <!-- <div class="year-box">
              <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy"
                popper-class='yearStyle' placeholder="选择年" @change="yearchange" :clearable='false'
                :picker-options="yearoptions">
              </el-date-picker>
            </div>
            <div class="month-box">
              <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
                format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions">
              </el-date-picker>
            </div>
            <div class="day-box" style="border-right: none;">
              <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions">
              </el-date-picker>
            </div> -->
            <!-- <div class="select-box">
              <el-select v-model="search_time" placeholder="请选择" @change="dangqichange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div> -->
            <div class="all-time">
              <div class="re-data">
                <div class="day-box" style="border-right: none;">
                  <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                    format="" placeholder="" @change="datechange" :clearable='false' :picker-options="dayoptions">
                  </el-date-picker>
                </div>
              </div>
              <div class="year-box">
                <el-date-picker v-model="year_value" type="year" value-format="yyyy" format="yyyy" popper-class='yearStyle'
                  placeholder="选择年" @change="yearchange" :clearable='false' :picker-options="yearoptions" disabled>
                </el-date-picker>
              </div>
              <div class="month-box">
                <el-date-picker v-model="month_value" type="month" popper-class='monthStyle' value-format="yyyy-MM"
                  format="MM" placeholder="选择月" @change="monthchange" :clearable='false' :picker-options="monthoptions" disabled>
                </el-date-picker>
              </div>
              <div class="day-box" style="border-right: none;">
                <el-date-picker v-model="day_value" type="date" popper-class='dateStyle' value-format="yyyy-MM-dd"
                  format="dd" placeholder="选择日" @change="datechange" :clearable='false' :picker-options="dayoptions" disabled>
                </el-date-picker>
              </div>
            </div>
            <div class="search-btn" @click="inquiry">查询</div>
          </div>
        </div>
        <div class="relust" :class="hide? 'showdiv':''">搜索结果：</div>
        <!-- 有数据时 -->
        <div class="video-main-box" v-if="HaveData">
          <div class="video-list" v-for="(item,index) in DataList" :key="index">
            <div class="y-new-title" v-if="item.segment == '创始人' && item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/chuangshiren.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '总监' && item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/zongjian.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '首席' && item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/shouxi.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '高级' && item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/gaoji.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '中级' && item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/zhongji.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="y-new-title" v-if="item.segment == '专业' && item.arr.length > 0">
              <div class="video-logo">
                <img src="../../assets/images/sidder/zhuanye.png" alt="">
              </div>
              <div class="video-jw">可定机位：<span>{{item.grade}}个</span></div>
            </div>
            <div class="all-photographer">
              <div class="all-plg-item" v-for="(item1,index1) in item.arr" :key="index1.id"
                @click="changepage(item1.id,item1.name)">
                <div class="plg-item">
                  <!-- <div class="top-box" :class="item.segment == '创始人' ? 'csr-color-backbg':
									item.segment == '总监' ? 'zj-color-backbg': item.segment == '首席' ? 'sx-color-backbg':item.segment == '高级' ? 'gj-color-backbg':
									item.segment == '中级' ? 'zji-color-backbg':item.segment == '专业' ? 'zy-color-backbg':''"></div> -->

                  <!-- <div class="footer-box" :class="item.segment == '创始人' ? 'csr-footer-backbg':
									item.segment == '总监' ? 'zj-footer-backbg': item.segment == '首席' ? 'sx-footer-backbg':item.segment == '高级' ? 'gj-footer-backbg':
									item.segment == '中级' ? 'zji-footer-backbg':item.segment == '专业' ? 'zy-footer-backbg':''"></div> -->

                  <div class="top-box zy-color-backbg"></div>
                  <div class="footer-box zy-footer-backbg"></div>
                  <img :src="item1.image" alt="">
                </div>
                <div class="coll-info">
                  <span class="info-name">{{item1.name}}</span>
                  <!-- <span class="info-price"
										:style="{'color':(item.segment == '创始人' ? '#950BC9': item.segment == '总监' ? '#4DA0E7':item.segment == '首席' ? '#F7AE56':
										item.segment == '高级' ? '#8B80D5':item.segment == '中级' ? '#A7624A':item.segment == '专业' ? '#4A4D5E':'')}">￥{{item1.price}}</span> -->

                  <span class="info-price" style="color:#4A4D5E">￥{{item1.price}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 无数据时 -->
        <div v-else class="nodata">
          <img src="../../assets/images/sidder/zanwuneirong.png" alt="">
          暂无数据~
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import BannerOther from '@/components/banner-other/index.vue'
  import Sidder from '@/components/sidder/index.vue'
  import {
    gethlsheyingList,
    getjiweilist
  } from '@/api/joggle.js'
  export default {
		name:'Wedding-Photography',
    props: ['keyword'],
    components: {
      BannerOther,
      Sidder
    },
    data() {
      return {
        img_url: '',
        chinese_name: '婚礼摄影',
        english_name: 'WEDDING PHOTOGRAPHY',
        tab_selectIndex: 1,
        year_value: '',
        month_value: '',
        day_value: '',
        search_date: '', //搜索年月日
        search_time: '', // 搜索时间
        nowyear: '', //当前年份
        nowmonth: '', //当前月份
        nowday: '', //当前日期
        options: [{
          value: '1',
          label: '上午档'
        }, {
          value: '2',
          label: '下午档'
        }],
        DataList: [{
            segment: '创始人',
            arr: []
          },
          {
            segment: '总监',
            arr: []
          },
          {
            segment: '首席',
            arr: []
          },
          {
            segment: '高级',
            arr: []
          },
          {
            segment: '中级',
            arr: []
          },
          {
            segment: '专业',
            arr: []
          }
        ],
        yearoptions: {
          disabledDate(time) {
            return time.getTime() < new Date();
          }
        },
        monthoptions: {
          disabledDate(time) {
            return time.getTime() < new Date();
          }
        },
        dayoptions: {
          disabledDate(time) {
            var showyear = time.getFullYear();
            const nowyear = new Date().getFullYear();
            var showmonth = time.getMonth() + 1;
            const nowmonth = new Date().getMonth() + 1;
            if (showyear < nowyear) { // 过去的年份
              return true
            } else if (showyear == nowyear) { // 现在的年份
              if (showmonth < nowmonth) {
                return true
              } else if (showmonth == nowmonth) {
                var showday = time.getDate();
                const nowday = new Date().getDate();
                return showday < nowday
              } else {
                return false
              }
            } else {
              return false
            }

          }
        },
        top_keyword: '',
        hide: false,
        HaveData: false,

        month1: '',
        day1: ''
      }
    },
    activated() {
      var that = this
			console.log('摄影')
      // 获取头图(栏目图)
      var company_info = JSON.parse(localStorage.getItem('company_info'));
      if (company_info) {
        that.img_url = company_info.sheying_image;
      }
      // 判断是否有搜索项
      that.search_time = that.$route.query.search_time;
      that.search_date = that.$route.query.search_date;
      that.top_keyword = that.$route.query.top_keyword;
      if (!that.top_keyword) {
        that.top_keyword = ''
      }
      if (that.search_time || that.search_date) {
        var firstnum = that.search_date.indexOf('-');
        var lastnum = that.search_date.lastIndexOf('-');
        that.year_value = that.search_date.slice(0, firstnum);
        that.month_value = that.search_date.slice(0, lastnum);
        that.day_value = that.search_date;
      } else {
        var data = new Date();
        // 默认当前年份
        that.nowyear = data.getFullYear().toString();
        // 默认当前月份
        that.nowmonth = (data.getMonth() + 1).toString() < 10 ? '0' + (data.getMonth() + 1).toString() : (data
          .getMonth() + 1).toString();
        // 默认当前日期
        that.nowday = data.getDate().toString() < 10 ? '0' + data.getDate().toString() : data.getDate().toString();
        that.year_value = that.nowyear;
        that.month_value = that.year_value + '-' + that.nowmonth;
        that.day_value = that.month_value + '-' + that.nowday;
      }
			
      that.getList();
    },
    watch: {
      // 顶部搜索
      keyword(val, oldval) {
        var that = this
        that.top_keyword = val;
        if (that.top_keyword == '') {
          that.getList();
        } else {
          that.getList('姓名搜索');
        }
        that.getNumber();
      }
    },
    methods: {
      getNumber() { //获取可定机位数
        var that = this
        var obj = {
          date: that.day_value
        }
        getjiweilist(obj).then(res => {
          if (res.code == 1) {
            var data = res.data;
            that.DataList.forEach((item, index) => {
              if (item.segment == '创始人') {
                item.grade = data.chuangshi_num;
              }
              if (item.segment == '总监') {
                item.grade = data.zongjian_num;
              }
              if (item.segment == '首席') {
                item.grade = data.shouxi_num;
              }
              if (item.segment == '高级') {
                item.grade = data.gaoji_num;
              }
              if (item.segment == '中级') {
                item.grade = data.zhongji_num;
              }
              if (item.segment == '专业') {
                item.grade = data.zhuanye_num;
              }
            })
            // 数据为空时
            if (that.DataList[0].arr.length == 0 && that.DataList[1].arr.length == 0 && that.DataList[2].arr
              .length == 0 && that.DataList[3].arr.length == 0 && that.DataList[4].arr.length == 0 && that.DataList[
                5].arr.length == 0) {
              that.HaveData = false;
            } else {
              that.HaveData = true;
            }

          }
        })
      },
      // 点击查询
      inquiry() {
        var that = this
        // 年月日处理  未进行日期选择时，查询当前时间
        if (that.search_date == '') {
          that.search_date = that.day_value;
        }
        that.top_keyword = '';
        that.getList();
        that.getNumber();
      },
      // 档期选择时
      dangqichange(e) {
        var that = this
        if (e) {
          that.search_time = e;
        }
      },
      // 年份变化时
      yearchange(e) {
        var that = this
        if (e) {
          that.year_value = e;
          var full = 'null';
          that.month_value = that.year_value + '-' + full;
          that.day_value = that.year_value + '-' + full + '-' + full;
          console.log(that.month_value, that.day_value)
          // if(that.month1){
          //   that.month_value = that.month1
          // }else{
          //   that.month_value = that.year_value + '-' + that.nowmonth;
          // }
          // if(that.day1){
          //   that.day_value = that.day1;
          // }else{
          //   that.day_value = that.month_value + '-' + that.nowday;
          // }
          that.search_date = that.day_value; // 搜索日期
        }
      },
      // 月份变化时
      monthchange(e) {
        var that = this
        if (e) {
          that.month_value = e;
          that.month1 = that.month_value;
          // 更新年份
          var y_num = e.indexOf('-');
          that.year_value = e.slice(0, y_num);
          // 更新日期
          var day = null
          that.day_value = that.month_value + '-' + day
          that.search_date = that.day_value; // 搜索日期
          console.log('月', that.search_date)
        }
      },
      // 日期变化时
      datechange(e) {
        var that = this
        if (e) {
          that.day_value = e;
          // 更新年份 月份
          var y_num = e.indexOf('-');
          that.year_value = e.slice(0, y_num);
          var m_num = e.lastIndexOf('-');
          that.month_value = e.slice(0, m_num);

          that.search_date = that.day_value; // 搜索日期
        }
      },
      // 获取婚礼摄影列表
      getList(name) {
        var that = this
        if (that.top_keyword != '') {
          name = '姓名搜索'
        } else {
          name = ''
        }
        var obj = {
          date: that.search_date,
          time: that.search_time,
          keyword: that.top_keyword
        }
        gethlsheyingList(obj).then(res => {
          if (res.code == 1) {

            if (name == '姓名搜索') {
              that.hide = true;
            } else {
              that.hide = false;
            }
            that.DataList.forEach((item, index) => {
              if (item.segment == '创始人') {
                item.arr = res.data[1];
              }
              if (item.segment == '总监') {
                item.arr = res.data[2];
              }
              if (item.segment == '首席') {
                item.arr = res.data[3];
              }
              if (item.segment == '高级') {
                item.arr = res.data[4];
              }
              if (item.segment == '中级') {
                item.arr = res.data[5];
              }
              if (item.segment == '专业') {
                item.arr = res.data[6];
              }
            })
            that.getNumber();
          }
        })
      },
      changepage(id, name) {
        var that = this
        that.$router.push({
          path: '/photography-details',
          query: {
            operate: '婚礼摄影',
            id: id,
            name: name,
            search_date: that.search_date,
            search_time: that.search_time,
            top_keyword: that.top_keyword
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '../../assets/css/wedding-photography.scss';
  @import '../../assets/css/public.scss';
</style>
